<template>
  <div class="m-stack__item m-stack__item--middle m-brand__logo">
    <router-link
      :to="{path: '/home'}"
      class="m-brand__logo-wrapper"
    >
      <img
        alt="Alturos-Logo"
        src="@/assets/alturos-destinations-logo-2017.png"
      >
    </router-link>
  </div>
</template>

<script>
export default {
    name: "Logo"
}
</script>
